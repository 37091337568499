import objectFitFallback from './modules/object-fit';
import './modules/check-os-browser';
import './modules/handle-mobile-nav';

(function(window, document, $) {
	// Your includes goes here...

  /*
   * Object Fit IE11 fallback init.
   */
  objectFitFallback();

})(window, document, window.jQuery);
