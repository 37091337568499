import { $body } from '../utils/globals.js';

const $triggerBtn =  $('.js-nav-trigger');

$triggerBtn.on('click', function(event){
	event.preventDefault();
	
	const $this = $(this);

	$this.toggleClass('is-active');
	$body.toggleClass('has-active-nav');
});