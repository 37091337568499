import { isIe } from '../utils/is-ms';

/**
 * Object fit IE11 fallback.
 *
 * @return {void}
 */
const objectFitFallback = () => {
  if (isIe()){
    $('.js-image-fit').each((i, container) => {
      const $container = $(container);
      const $img = $container.find('img');
      const src = $img.attr('src') || $img.data('src');

      $container.css('background-image', `url(${src})`);
      $img.addClass('sr-only');
    });
  }
}

export default objectFitFallback;
