import { $body } from '../utils/globals.js';

var OSName = 'Unknown OS'; 

function operatingSytem() {  
  if (navigator.appVersion.indexOf('Win')!=-1) OSName='Windows'; 
  if (navigator.appVersion.indexOf('Mac')!=-1) OSName='MacOS'; 
  if (navigator.appVersion.indexOf('X11')!=-1) OSName='UNIX'; 
  if (navigator.appVersion.indexOf('Linux')!=-1) OSName='Linux'; 
    
  if(OSName==='MacOS') {
  	return OSName;
  }
}

var isChromium = window.chrome;
var winNav = window.navigator;
var vendorName = winNav.vendor;
var isOpera = typeof window.opr !== 'undefined';
var isIEedge = winNav.userAgent.indexOf('Edge') > -1;
var isIOSChrome = winNav.userAgent.match('CriOS');

if (isIOSChrome) {
   // is Google Chrome on IOS
} else if(
  isChromium !== null &&
  typeof isChromium !== 'undefined' &&
  vendorName === 'Google Inc.' &&
  isOpera === false &&
  isIEedge === false
) {
	operatingSytem();

	if(OSName==='MacOS') {
  	$body.addClass('isChromeMac');
	}
}